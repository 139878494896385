import type { Iti } from "intl-tel-input";

import utils from "../utilsV2/currency";
import type { CurrencyCode } from "@/static/types";

export const checkPasswordValidity = (password: string) => {
    // At least one LOWERCASE character:
    const lowerCasePattern = /^(?=.*[a-z]).+$/;

    // At least one UPPERCASE character:
    const upperCasePattern = /^(?=.*[A-Z]).+$/;

    // At least one NUMBER:
    const numberPattern = /^(?=.*[\d]).+$/;

    // At least one SPECIAL character:
    const specialCharacterPatter = /([-+=_!@#$%^&*.,;:'"<>/?`~[\](){}\\|\s])/;

    // At least 10 characters in the screen:
    const characterCountPattern = /^.{10,}/;

    // Flag to keep track if password is less than 50 characters:
    let isLessThan50 = true;

    if (password.length > 50) isLessThan50 = false;

    let strength = 0;
    lowerCasePattern.test(password) ? strength++ : "";
    upperCasePattern.test(password) ? strength++ : "";
    numberPattern.test(password) ? strength++ : "";
    specialCharacterPatter.test(password) ? strength++ : "";
    characterCountPattern.test(password) ? strength++ : "";
    isLessThan50 ? strength++ : "";
    return strength;
};

export const emailIsValid = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const isValidDate = (value: string) => {
    return value && new Date(value).toString() !== "Invalid Date";
};

export const itiValidatePhoneNumber = (iti: Iti, $_) => {
    const errorCode = iti.getValidationError();
    switch (errorCode) {
        case 1:
            return $_("validation.phoneNumberInvalidCountryCode");
        case 2:
            return $_("validation.phoneNumberTooShort");
        case 3:
            return $_("validation.phoneNumberTooLong");
        case 5:
            return $_("validation.phoneNumberInvalidLength");
        default:
            return "";
    }
};

export const validatePhoneNumber = (
    $_,
    countryCode?: string,
    phoneNumber?: string,
    validateIfPrivate: boolean = true
) => {
    const INVALID_PHONE_NUMBER = $_("validation.invalidPhoneNumber");
    const INVALID_PHONE_NUMBER_NO = $_("validation.phoneNumberNorway");
    let errorMessage = "";
    if (!phoneNumber) {
        errorMessage = $_("validation.phoneNumberRequired");
    } else if (countryCode) {
        // Norway
        if (countryCode === "+47") {
            if (
                (phoneNumber.startsWith("+47") && phoneNumber.length != 11) ||
                (!phoneNumber.startsWith("+47") && phoneNumber.length !== 8)
            ) {
                errorMessage = INVALID_PHONE_NUMBER;
            }
            if (validateIfPrivate && !phoneNumber.startsWith("4") && !phoneNumber.startsWith("9")) {
                errorMessage = INVALID_PHONE_NUMBER_NO;
            }
            // GB
        } else if (countryCode === "+44") {
            if (phoneNumber.length < 9 || phoneNumber.length > 14) {
                errorMessage = INVALID_PHONE_NUMBER;
            }
            // Sweden
        } else if (countryCode === "+46") {
            if (phoneNumber.length < 7 || phoneNumber.length > 13) {
                errorMessage = INVALID_PHONE_NUMBER;
            }
        }
    }
    return errorMessage;
};

// Todo(hpatel): remove validateCustomerRecourseAmount method once we enable the new feature for validating
// the recourse amount on max allowed amount fetching it form endpoint
// remove once the feature flag: 'enable_fe_validate_customer_recourse_limit' is enabled on prod after testing.
export const validateCustomerRecourseAmount = (
    value: string,
    currency: CurrencyCode,
    $_
): string => {
    let error = "";
    let max_amount = {
        GBP: 100000,
        NOK: 1000000,
        SEK: 1000000,
        USD: 100000,
        EUR: 100000
    }[`${currency}`];

    if (parseFloat(value) > max_amount) {
        error = $_("validation.amountExceedsLimit", {
            values: { maxAmount: utils.formatCurrency(currency)(max_amount) }
        });
    }

    return error;
};

export const validateCustomerRecourseAmountWithMaxAllowed = (
    value: string,
    max_amount_allowed: string,
    currency: CurrencyCode,
    $_
): string => {
    let error = "";

    if (parseFloat(value) > parseFloat(max_amount_allowed)) {
        error = $_("validation.aboveRiskAppetite", {
            values: { maxAmount: utils.formatCurrency(currency)(max_amount_allowed) }
        });
    }

    return error;
};
