<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Button } from "@two-ui/core";

    import environment from "@/services/environment";
    import CardCentered from "../../../lib/components/common/CardCentered.svelte";
    import authenticationService from "@/services/authenticationService";

    export let buyerEmail: string;
    export let step: "EMAIL" | "CODE";

    let errorMessage: string = "";
    let isLoading = false;

    async function buyerLogin() {
        await startLogin();
    }

    async function startLogin() {
        isLoading = true;
        const formValue = {
            verification_type: "EMAIL",
            verification_context: "BUYER_PORTAL",
            email: buyerEmail
        };
        authenticationService
            .sendEmailCode(formValue)
            .then((res: any) => {
                localStorage.setItem("verification_id", res.verification_id);
                localStorage.setItem("redirect_url", res.redirect_url);

                step = "CODE";
            })
            .catch(() => {
                errorMessage = $_("login.unexpectedError", {
                    values: { supportEmail: environment.branding.supportEmail }
                });
            })
            .finally(() => {
                isLoading = false;
            });
    }

    const clearErrorMessage = () => (errorMessage = "");
</script>

<CardCentered>
    <header>
        <h1>
            {$_("login.loginToBuyerPortal", {
                values: { brand: environment.branding.displayName }
            })}
        </h1>
        <p>
            {$_("login.accessPortalDescription", {
                values: { brand: environment.branding.displayName }
            })}
        </p>
    </header>
    <form class="email-input" on:submit|preventDefault={buyerLogin} on:input={clearErrorMessage}>
        <p class="email-input-description">{$_("login.emailAddress")}</p>
        <input
            id="buyer_email"
            required
            type="email"
            placeholder="jane@acme.co"
            bind:value={buyerEmail}
        />
        <p id="error-message">
            {errorMessage}
        </p>
        <div class="button-continue">
            <Button
                id="buyer-email-btn"
                size="md"
                variant="primary"
                content={$_("components.continue")}
                loading={isLoading}
                fullSize
            />
        </div>
    </form>
</CardCentered>

<style lang="scss">
    header {
        text-align: center;

        h1 {
            font-size: 1.25rem;
            font-weight: 700;
        }

        p {
            margin-top: 1.25rem;
        }
    }

    .email-input {
        margin-top: 1.5rem;

        .email-input-description {
            font-size: 0.875rem;
            font-weight: 500;
        }

        input {
            margin-top: 0.375rem;
            width: 100%;
            padding: 10px 14px;
            border: 0.0625rem solid #d0d5dd;
            border-radius: 8px;
        }

        #error-message {
            color: red;
            font-size: 0.875rem;
            font-weight: 500;
            height: 1.5rem;
        }

        .button-continue {
            margin-top: 1rem;
        }
    }
</style>
