import { getBrandThemeNameByUrl, BrandThemeName } from "@two-ui/core";

import { enableMocking } from "./services/http/msw";
import App from "./App.svelte";
import "./sentry";
import "./main.css";

const themeMap: Record<BrandThemeName, () => Promise<unknown>> = {
    default: () => import("@two-ui/core/white-label/default.css"),
    choco: () => import("@two-ui/core/white-label/choco.css"),
    achterafbetalen: () => import("@two-ui/core/white-label/abn.css"),
    komplett: () => import("@two-ui/core/white-label/komplett.css"),
    itegra: () => import("@two-ui/core/white-label/itegra.css")
};
// inject white-label css
const themeName = getBrandThemeNameByUrl();
await themeMap[themeName]();

enableMocking()
    .then(() => {
        new App({
            target: document.body
        });
    })
    .catch((e) => console.error("enableMocking failed with ", e));
