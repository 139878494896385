<script lang="ts">
    import { _, locale, locales } from "svelte-i18n";
    import { snakeCase, uniqueId } from "lodash";

    import FieldImage from "@/components/account/FieldImage.svelte";
    import Field from "@/components/account/Field.svelte";
    import {
        recourseLimitConfig,
        account as merchantAccount,
        recourseLoading,
        recourseEnabled,
        actions,
        signupComplete
    } from "@/store/merchant/account.store";
    import { getInitialRecourseLimitsFromConfig } from "@/utils/functions";
    import utils from "@/utilsV2/currency";
    import { titleCase } from "@/utilsV2/helpers";
    import { COUNTRIES, GB, US } from "@/static/constant";
    import DocumentTable from "./DocumentTable.svelte";
    import { formatSortCode } from "@/utils/methods";
    import { type IAccountSection, type IGridSection, NotificationType } from "@/static/types";
    import notificationState from "@/store/notifications.store";
    import { MERCHANT_UPDATE } from "@/static/endPoints";
    import API from "@/services/apiService";
    import environment from "@/services/environment";

    let remainingRecourseLimit = null;
    let totalRecourseLimit = null;
    let currentRecourseExposure = null;
    let accountSections: Record<string, IAccountSection> = {};
    let recourseCurrency = "GBP";
    let editOngoing = false;
    let successfulImageUpload = false;
    let countryCode = "";
    let highlightSection = "";
    interface updateRequest {
        (data: object): Promise<unknown>;
    }
    let updateMerchantFunction: updateRequest;

    const updateSignup = (data: object) =>
        actions.updateMerchantSignup(data, $merchantAccount.application_id);
    const updateMerchant = (data: object) => API.patch(MERCHANT_UPDATE, data);

    function isNotNorwegianMerchant() {
        return countryCode != COUNTRIES.NO.country_code;
    }

    function getTranslatedLanguage(lang) {
        switch (lang) {
            case "nl":
                return $_("languages.nl");
            case "fi":
                return $_("languages.fi");
            case "no":
                return $_("languages.no");
            case "sv":
                return $_("languages.sv");
            case "da":
                return $_("languages.da");
            case "es":
                return $_("languages.es");
            default:
                return $_("languages.en");
        }
    }

    function getTermsOptions() {
        if ($merchantAccount.partner_short_name?.toUpperCase() === "ABN") {
            return new Map([[30, $_("netTerms.days", { values: { amount: 30 } })]]);
        }
        return new Map([
            [14, $_("netTerms.days", { values: { amount: 14 } })],
            [21, $_("netTerms.days", { values: { amount: 21 } })],
            [30, $_("netTerms.days", { values: { amount: 30 } })],
            [45, $_("netTerms.days", { values: { amount: 45 } })],
            [60, $_("netTerms.days", { values: { amount: 60 } })],
            [90, $_("netTerms.days", { values: { amount: 90 } })]
        ]);
    }

    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                const id = entry.target.getAttribute("id");
                if (entry.intersectionRatio >= 0.99 || highlightSection == id) {
                    document.querySelector(`nav a[href="#${id}"]`)?.classList.add("active");
                } else {
                    document.querySelector(`nav a[href="#${id}"]`)?.classList.remove("active");
                }
            });
        },
        { threshold: [0, 0.25, 0.5, 0.75, 0.99, 1], root: document.querySelector("#test-cont") }
    );

    $: updateMerchantFunction = $signupComplete ? updateMerchant : updateSignup;

    $: if ($merchantAccount && !$recourseLoading) {
        countryCode = $merchantAccount.countryCode;
        if ($recourseEnabled) {
            const limits = getInitialRecourseLimitsFromConfig($recourseLimitConfig);
            remainingRecourseLimit = limits.remainingRecourseLimit;
            totalRecourseLimit = limits.totalRecourseLimit;
            currentRecourseExposure = limits.currentRecourseExposure;
            recourseCurrency = limits.recourseCurrency;
        }
        accountSections = {
            Profile: {
                title: $_("account.titles.profile"),
                subtitle: $_("account.profileSubtitle"),
                fields: [
                    {
                        label: $_("account.labels.logo"),
                        type: "image",
                        key: "logoPath",
                        value: $merchantAccount.logoPath,
                        explanatory_text: "",
                        required: false,
                        editable: true,
                        interactionId: uniqueId()
                    },
                    {
                        label: $_("account.labels.legalName"),
                        type: "text",
                        key: "legalName",
                        value: $merchantAccount.legalName,
                        explanatory_text: $_("account.legalNameExplanatory")
                    },
                    {
                        label: $_("account.labels.registeredAddress"),
                        key: "nationalID",
                        value: $merchantAccount.formattedRegisterdAddress,
                        type: "text"
                    },
                    {
                        label: "National ID",
                        key: "nationalID",
                        value: $merchantAccount.nationalID,
                        type: "text"
                    }
                ]
            },
            Webshop_details: {
                title: $_("account.titles.webshopDetails"),
                fields: [
                    {
                        label: $_("account.labels.tradingName"),
                        type: "text",
                        key: "displayName",
                        value: $merchantAccount.accountName,
                        required: true,
                        editable: true,
                        placeholder: $_("account.placeholders.tradingName"),
                        interactionId: uniqueId()
                    },
                    {
                        label: $_("account.labels.website"),
                        key: "website",
                        value: $merchantAccount.website,
                        type: "text",
                        required: false,
                        editable: true,
                        placeholder: $_("account.placeholders.website"),
                        interactionId: uniqueId()
                    }
                ]
            },
            Invoice_settings: {
                title: $_("account.titles.invoiceSettings"),
                subtitle: $_("account.invoiceSettingsSubtitle"),
                fields: [
                    {
                        label: $_("account.labels.companyEmail"),
                        key: "emailInvoice",
                        value: $merchantAccount.emailInvoice,
                        type: "email",
                        required: true,
                        editable: true,
                        placeholder: $_("account.placeholders.companyEmail"),
                        interactionId: uniqueId()
                    },
                    {
                        label: $_("account.labels.companyPhone"),
                        key: "phoneInvoice",
                        value: $merchantAccount.phoneInvoice,
                        type: "phone",
                        required: false,
                        editable: true,
                        placeholder: $_("account.placeholders.companyPhone"),
                        interactionId: uniqueId()
                    },
                    {
                        label: $_("account.labels.defaultTerms"),
                        key: "dueInDays",
                        value: Number($merchantAccount.standardTerms) || 14,
                        type: "dropdown",
                        required: true,
                        editable: true,
                        selectorInput: {
                            options: getTermsOptions(),
                            placeholder:
                                $merchantAccount.standardTerms ||
                                $_("netTerms.days", { values: { amount: 14 } })
                        }
                    }
                ]
            },
            Language_settings: {
                title: $_("account.titles.languageSettings"),
                subtitle: "",
                fields: [
                    {
                        label: $_("account.labels.languagePortal"),
                        key: "lang",
                        value: $locale,
                        type: "dropdown",
                        required: true,
                        editable: true,
                        selectorInput: {
                            options: new Map(
                                $locales.map((locale) => [locale, getTranslatedLanguage(locale)])
                            ),
                            placeholder: getTranslatedLanguage($locale)
                        }
                    },
                    {
                        label: $_("account.labels.languageInvoices"),
                        key: "",
                        value: $_("account.customersLanguage"),
                        type: "text",
                        required: false,
                        editable: false
                    }
                ]
            },
            Account_contact_details: {
                title: $_("account.titles.accountContactDetails"),
                subtitle: $_("account.contactDetails", {
                    values: { brandName: environment.branding.displayName }
                }),
                fields: [
                    {
                        label: "Email",
                        key: "email",
                        value: $merchantAccount.email,
                        type: "text",
                        required: $signupComplete,
                        editable: true,
                        placeholder: $_("account.placeholders.companyEmail"),
                        interactionId: uniqueId()
                    },
                    {
                        label: "Phone",
                        key: "phone",
                        value: $merchantAccount.phone || $merchantAccount.merchantPhoneCountryCode,
                        type: "phone",
                        required: true,
                        editable:
                            (isNotNorwegianMerchant() && $signupComplete) ||
                            (!$merchantAccount.phone && !$signupComplete),
                        placeholder: $_("account.placeholders.companyPhone"),
                        interactionId: uniqueId()
                    }
                ]
            },
            Fraud_contact_details: {
                title: $_("account.titles.fraudContactDetails"),
                subtitle: $_("account.fraudContacts", {
                    values: { brandName: environment.branding.displayName }
                }),
                fields: [
                    {
                        label: $_("account.labels.fraudEmail"),
                        // fullstop allows key to be split and reconstructed into object
                        key: "fraudContact.email",
                        value: $merchantAccount.fraud_contact?.email,
                        type: "email",
                        required: false,
                        editable: true,
                        placeholder: $_("account.placeholders.companyEmail"),
                        interactionId: uniqueId()
                    },
                    {
                        label: $_("account.labels.fraudPhone"),
                        key: "fraudContact.phone_number",
                        value:
                            $merchantAccount.fraud_contact.phone ||
                            $merchantAccount.merchantPhoneCountryCode,
                        type: "phone",
                        required: false,
                        editable: true,
                        placeholder: $_("account.placeholders.companyPhone"),
                        interactionId: uniqueId()
                    }
                ]
            },
            Documents: {
                title: $_("account.titles.documents"),
                documents: $merchantAccount.documents
            },
            Payout_details: {
                title: $_("account.titles.payoutDetails"),
                subtitle: $_("account.payoutDetailsSubtitle", {
                    values: { supportEmail: environment.branding.supportEmail }
                }),
                fields: [
                    {
                        title: $_("account.labels.accountDetails"),
                        gridSections: Object.entries(groupPayoutAccountsIntoSubFields()).map(
                            ([key, value]) => {
                                return <IGridSection>{
                                    gridTitle: key,
                                    gridFields: value
                                };
                            }
                        )
                    },
                    {
                        title: $_("account.labels.payoutFrequency"),
                        label: $_("account.labels.payoutPlan"),
                        value: $merchantAccount.payoutPlan
                            ? titleCase([$merchantAccount.payoutPlan])[0]
                            : "Not set",
                        required: true,
                        editable: false
                    }
                ]
            },
            Recourse_fallback_limits: $recourseEnabled
                ? {
                      title: $_("account.titles.recourseFallbackLimits"),
                      subtitle: $_("account.recourseFallbackSubtitleEnabled"),
                      fields: [
                          {
                              gridSections: [
                                  {
                                      gridFields: [
                                          {
                                              [$_("account.recourseLimitLabels.total")]:
                                                  utils.formatCurrency(recourseCurrency)(
                                                      totalRecourseLimit,
                                                      null
                                                  ),
                                              [$_("account.recourseLimitLabels.currentExposure")]:
                                                  utils.formatCurrency(recourseCurrency)(
                                                      currentRecourseExposure,
                                                      null
                                                  ),
                                              [$_("account.recourseLimitLabels.remainingLimit")]:
                                                  utils.formatCurrency(recourseCurrency)(
                                                      remainingRecourseLimit,
                                                      null
                                                  )
                                          }
                                      ]
                                  }
                              ]
                          }
                      ]
                  }
                : {
                      title: $_("account.recourse"),
                      subtitle: $_("account.recourseFallbackSubtitleDisabled", {
                          values: { supportEmail: environment.branding.supportEmail }
                      })
                  }
        };
    }

    function addNodeToObserver(node: Element) {
        observer.observe(node);
    }

    function groupPayoutAccountsIntoSubFields() {
        const countryMap = {};

        $merchantAccount?.payout_accounts?.forEach((account) => {
            let bankAccount = {
                [$_("account.accountName")]: account.recipient_name ?? $_("account.notSet"),
                [$_("account.currency")]: account.currency
            };
            bankAccount = {
                ...bankAccount,
                ...countryBankAccountFields(account.bank_country_code, account)
            };
            if (countryMap[account.bank_country_code]) {
                countryMap[account.bank_country_code].push(bankAccount);
            } else {
                countryMap[account.bank_country_code] = [bankAccount];
            }
        });
        return countryMap;
    }

    const countryBankAccountFields = (countryCode, payoutAccount) => {
        let accountDetails = null;
        switch (countryCode) {
            case GB:
                accountDetails = {
                    [$_("account.accountNumber")]:
                        payoutAccount.local_account_number || $_("account.notSet"),
                    [$_("account.sortCode")]:
                        formatSortCode(payoutAccount.branch_sort_code) || $_("account.notSet")
                };
                break;
            case US:
                accountDetails = {
                    [$_("account.BBAN")]: payoutAccount.bban ?? $_("account.notSet"),
                    [$_("account.AchRoutingNumber")]:
                        payoutAccount.branch_sort_code ?? $_("account.notSet")
                };
                break;
            default:
                accountDetails = {
                    BBAN: payoutAccount.bban ?? $_("account.notSet"),
                    BIC: payoutAccount.bic ?? $_("account.notSet")
                };
                break;
        }
        return accountDetails;
    };

    function onImageChanged(
        field: any,
        imageData: { logo_file_name: string; logo_base64: string; mimeType: string }
    ) {
        successfulImageUpload = false;
        updateMerchantFunction(imageData)
            .then(() => {
                notificationState.actions.create(
                    NotificationType.SUCCESS,
                    $_("account.imageUpdated")
                );
                successfulImageUpload = true;
            })
            .catch((err) => {
                const message: string =
                    err.response?.data?.error_message || $_("account.imageUpdateFailed");
                notificationState.actions.create(NotificationType.ERROR, message, field.label);
                successfulImageUpload = false;
            });
    }

    async function onInputChanged(field: any, newValue: string, settingName: string) {
        const data = {};
        const fieldNames = field.key.split(".") as string[];
        let currentLevel = data;
        fieldNames.forEach((fieldName, index) => {
            // Iterate through the keys
            const key = snakeCase(fieldName);

            // If it's the last key, assign the value
            if (index === fieldNames.length - 1) {
                currentLevel[key] = newValue;
            } else {
                // Otherwise, create an empty object if it doesn't exist
                if (!currentLevel[key]) {
                    currentLevel[key] = {};
                }
                // Move to the next level
                currentLevel = currentLevel[key];
            }
        });

        if (field.key === "lang") {
            locale.set(newValue);
            localStorage.setItem("lang", newValue);
            notificationState.actions.create(NotificationType.SUCCESS, $_("account.langUpdated"));
            setTimeout(() => window.location.reload(), 2000);
        } else {
            postUpdateData(data, field.label, field, field.value, newValue, settingName);
        }
    }

    async function postUpdateData(
        data: object,
        label: string,
        field,
        oldValue,
        newValue,
        settingName
    ) {
        updateMerchantFunction(data)
            .then(() => {
                accountSections[settingName].fields[
                    accountSections[settingName].fields.findIndex(
                        (sField) => sField.label == field.label
                    )
                ].value = newValue;
                const saveDisplayText = $_("account.fieldSaved", { values: { field: label } });
                notificationState.actions.create(NotificationType.SUCCESS, saveDisplayText);
            })
            .catch(() => {
                accountSections[settingName].fields[
                    accountSections[settingName].fields.findIndex(
                        (sField) => sField.label == field.label
                    )
                ].value = oldValue;
                notificationState.actions.create(
                    NotificationType.ERROR,
                    $_("account.fieldNotSaved", { values: { field: label } })
                );
            })
            .finally(() => {
                accountSections[settingName].fields[
                    accountSections[settingName].fields.findIndex(
                        (sField) => sField.label == field.label
                    )
                ].interactionId = uniqueId();
            });
    }

    function sectionInEditMode(sectionName: string, isEditing: boolean) {
        highlightSection = isEditing ? sectionName : "";
    }
</script>

<div class="test-cont">
    <div class="card-grid">
        {#each Object.entries(accountSections) as [settingName, setting]}
            <div
                use:addNodeToObserver
                id={settingName}
                class="rounded-lg p-6 bg-white border border-user-gray-200 card"
            >
                {#if setting.documents}
                    <DocumentTable documents={setting.documents} {editOngoing} />
                {:else}
                    <h2
                        class="text-base font-bold w-full pb-2"
                        class:disabled={editOngoing && highlightSection !== settingName}
                    >
                        {setting.title}
                    </h2>
                    {#if setting?.subtitle}
                        <h3 class:disabled={editOngoing && highlightSection !== settingName}>
                            {@html setting.subtitle}
                        </h3>
                    {/if}
                    {#each setting?.fields ?? [] as field, fieldIndex}
                        <div class="field">
                            {#if field.title}
                                <h4
                                    class:disabled={editOngoing && highlightSection !== settingName}
                                >
                                    {field.title}
                                </h4>
                            {/if}
                            {#if field.type == "image"}
                                <FieldImage
                                    on:imageChanged={(e) => onImageChanged(field, e.detail)}
                                    on:edit={(e) =>
                                        sectionInEditMode(settingName, e.detail.isEditing)}
                                    logoPath={String(field.value)}
                                    successUpload={successfulImageUpload}
                                    {...field}
                                    bind:editOngoing
                                />
                            {:else if field.label}
                                {#key accountSections[settingName].fields[fieldIndex].interactionId}
                                    <Field
                                        on:edit={(e) =>
                                            sectionInEditMode(settingName, e.detail.isEditing)}
                                        on:inputChanged={(e) =>
                                            onInputChanged(field, e.detail.newValue, settingName)}
                                        {...field}
                                        bind:editOngoing
                                    />
                                {/key}
                            {/if}
                            {#if field?.gridSections && field.gridSections[0]?.gridFields}
                                {@const gridColumns = Object.keys(
                                    field.gridSections[0].gridFields[0]
                                ).map(() => "auto")}
                                <div
                                    class="grid-section"
                                    style="grid-template-columns:{gridColumns.join(' ')}"
                                >
                                    {#each field?.gridSections ?? [] as gridSection}
                                        {@const gridTitles = Object.keys(gridSection.gridFields[0])}
                                        {#if gridSection.gridTitle && field.gridSections.length > 1}
                                            <!-- subtitle should span full length of columns -->
                                            <p
                                                class:disabled={editOngoing &&
                                                    highlightSection !== settingName}
                                                class="grid-title"
                                                style="padding-top:1rem;grid-column:span {gridTitles.length}"
                                            >
                                                {gridSection.gridTitle}
                                            </p>
                                        {/if}
                                        {#each gridTitles as gridFieldTitle}
                                            <p
                                                class:disabled={editOngoing &&
                                                    highlightSection !== settingName}
                                                class="label account-label"
                                            >
                                                {gridFieldTitle}
                                            </p>
                                        {/each}
                                        {#each gridSection.gridFields as gridField}
                                            {#each Object.values(gridField) as gridFieldValue}
                                                <p
                                                    class:disabled={editOngoing &&
                                                        highlightSection !== settingName}
                                                >
                                                    {gridFieldValue}
                                                </p>
                                            {/each}
                                        {/each}
                                    {/each}
                                </div>
                            {/if}
                        </div>
                    {/each}
                {/if}
            </div>
        {/each}
    </div>
    <nav>
        {#each Object.entries(accountSections) as [settingName, setting]}
            <a class="active" href="#{settingName}">{setting.title}</a>
        {/each}
    </nav>
</div>

<style lang="scss">
    * :global(.fallback-link) {
        color: var(--primary-500);
    }
    .card-grid {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        max-width: 760px;
        margin-right: 2rem;
    }

    .card {
        min-width: 22rem;
    }

    .disabled {
        color: var(--gray-400);
    }

    nav {
        position: sticky;
        top: 0rem;
        align-self: start;
        a {
            padding: 1rem;
            text-decoration: none;
            display: block;
            color: var(--gray-800);
            font-weight: 400;
            transition: all 50ms ease-in-out;
        }
        a:hover {
            font-weight: 500;
        }
    }

    a:focus,
    a:active,
    a.active {
        font-weight: 700;
        color: var(--gray-800);
        border-left: 3px solid var(--primary-500);
    }

    .test-cont {
        display: grid;
        grid-template-columns: minmax(620px, 780px) minmax(15em, 1fr);
        position: relative;
        height: 85vh;
        padding: 1.5rem 2rem;
        overflow: auto;
    }
    h2 {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        color: var(--ctnGeneralPrimary);
    }
    h3 {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--ctnGeneralSecondary);
        padding-bottom: 0.5rem;
    }
    h4 {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: var(--gray-700);
        padding: 0.5rem 0rem;
    }
    :global(.label) {
        // TODO: we need to avoid using such global classes and styles
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--ctnGeneralSecondary);
        padding-bottom: 4px;
    }

    .account-label {
        padding-bottom: 0;
        margin-bottom: -4px;
    }

    .field {
        border-bottom: 1px solid var(--border-secondary);
        display: flex;
        gap: 4px;
        flex-direction: column;
        padding: 12px 0;
    }
    .field:last-child {
        border-bottom: none;
    }
    .grid-title {
        color: var(--gray-700);
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 1px;
        text-align: left;
        border-bottom: 1px solid var(--border-secondary);
    }
    .grid-section {
        padding: 0.5rem 0rem;
        grid-row-gap: 8px;
        grid-column-gap: 8px;
        display: grid;
    }
</style>
