<script lang="ts">
    import { _ } from "svelte-i18n";
    import { navigate } from "svelte-routing";

    import Table from "@/components/table/Table.svelte";
    import allOrdersState from "@/store/merchant/allOrders.store";
    import { account, recourseLimitConfig } from "@/store/merchant/account.store";
    import { onDestroy, onMount } from "svelte";
    import type { Unsubscriber } from "svelte/store";
    import type { IAllOrders, ISort, IMerchantRecourseLimit } from "@/static/types";
    import { objectSort, SORT_TYPE } from "@/utils/functions";
    import {
        buildAllOrdersTable,
        buildPendingOrdersTable,
        buildReadyToFulfilTable,
        buildFulfilledOrdersTable,
        buildRefundedOrdersTable,
        buildCancelledOrdersTable
    } from "./orderUtils";
    import { getTabItems } from "./tableConstants";

    export let tableType: string = "All";
    export let loading: boolean = false;

    function titleToTranslation(title) {
        switch (title) {
            case "Order №":
                return $_("order.table.headers.order_number");
            case "Invoice №":
                return $_("order.table.headers.invoice_number");
            case "Order status":
                return $_("order.table.headers.order_status");
            case "Purchase date":
                return $_("order.table.purchase_date");
            case "Customer details":
                return $_("order.table.customer_details");
            case "Amount":
                return $_("order.table.headers.amount");
            case "Payment due date":
                return $_("order.table.headers.payment_due_date");
            case "Amount fulfilled":
                return $_("order.table.headers.amount_fulfilled");
            case "Latest refund date":
                return $_("order.table.headers.latest_refund_date");
            case "Refunded":
                return $_("order.table.headers.refunded");
            case "Risk bearer":
                return $_("order.table.headers.risk_bearer");
            case "Action required":
                return $_("order.table.headers.action_required");
            case "Contact details":
                return $_("order.table.headers.contact_details");
            default:
                return title;
        }
    }

    function tooltipTitleToTranslation(title) {
        switch (title) {
            case "Action required":
                return $_("order.table.action_required_tooltip");
            case "Risk bearer":
                return $_("order.table.risk_bearer_tooltip");
            default:
                return title;
        }
    }

    function getRowTooltipTranslation(tooltip) {
        switch (tooltip) {
            case "Risk bearer not decided yet":
                return $_("order.table.rows.Risk_bearer_not_decided_yet");
            case "Funded invoice on recourse":
                return $_("order.table.rows.Funded_invoice_on_recourse");
            case "Funded invoice":
                return $_("order.table.rows.Funded_invoice");
            case "Direct invoice":
                return $_("order.table.rows.Direct_invoice");
            default:
                return tooltip;
        }
    }

    function getRowItemTranslation(item) {
        switch (item) {
            case "Not decided yet":
                return $_("order.table.rows.Not_decided_yet");
            case "Your company":
                return $_("order.table.rows.Your_company");
            case "Not issued":
                return $_("order.table.rows.Not_issued");
            case "Not set":
                return $_("order.table.rows.Not_set");
            case "Multiple invoices":
                return $_("order.table.rows.Multiple_invoices");
            default:
                return item;
        }
    }

    let tableHeaders = [];
    let tableRows = [];
    let pageNum = 1;
    let numAllRecords = 0;
    let allOrders: IAllOrders[] = [];
    let sortedOrders: any[] = [];
    let numDisplayedRecords = 0;

    let merchantRecourseLimit: IMerchantRecourseLimit;

    const PURCHASE_DATE = "order_date";
    const FULFILLED_DATE = "fulfill_date";
    const AMOUNT = "amount";
    const INITIAL_SORT = PURCHASE_DATE;

    let purchaseDateSort: ISort = "down";
    let fulfilledDateSort: ISort = "down";
    let amountSort: ISort = "down";

    let allOrdersUnsubscribe: Unsubscriber;

    let recourseFallbackEnabled: boolean = false;

    onMount(() => {
        allOrdersUnsubscribe = allOrdersState.subscribe((value: IAllOrders[]) => {
            setInitialData(value, INITIAL_SORT);
        });
        allOrdersState.numAllRecords.subscribe((value) => {
            numAllRecords = value as number;
        });

        allOrdersState.selectedPage((value: number) => {
            pageNum = value;
        });
    });

    $: if ($recourseLimitConfig) {
        // only if the recourseLimitConfig state is loaded
        merchantRecourseLimit = $recourseLimitConfig?.merchant_recourse_limits?.[0];
    }

    $: recourseFallbackEnabled = merchantRecourseLimit?.recourse_fallback_enabled;

    $: isNewDetailsPageEnabled = $account?.flags?.show_new_order_details_page === true;

    onDestroy(() => {
        allOrdersUnsubscribe();
    });

    $: {
        if (tableType === "All") {
            tableHeaders = recourseFallbackEnabled
                ? getTabItems("All", $_).tableHeadersRecourse
                : getTabItems("All", $_).tableHeaders;
            tableRows = buildAllOrdersTable(allOrders, $_);
        } else if (tableType === "Pending") {
            tableHeaders = getTabItems("Pending", $_).tableHeaders;
            tableRows = buildPendingOrdersTable(allOrders);
        } else if (tableType === "Ready to fulfill") {
            tableHeaders = getTabItems("Ready to fulfill", $_).tableHeaders;
            tableRows = buildReadyToFulfilTable(allOrders);
        } else if (tableType === "Fulfilled") {
            tableHeaders = recourseFallbackEnabled
                ? getTabItems("Fulfilled", $_).tableHeadersRecourse
                : getTabItems("Fulfilled", $_).tableHeaders;
            tableRows = buildFulfilledOrdersTable(allOrders);
        } else if (tableType === "Refunded") {
            tableHeaders = recourseFallbackEnabled
                ? getTabItems("Refunded", $_).tableHeadersRecourse
                : getTabItems("Refunded", $_).tableHeaders;
            tableRows = buildRefundedOrdersTable(allOrders);
        } else if (tableType === "Cancelled") {
            tableHeaders = getTabItems("Cancelled", $_).tableHeaders;
            tableRows = buildCancelledOrdersTable(allOrders);
        }
        // inject translations to headers
        tableHeaders = tableHeaders.map((item) => {
            const header = {
                ...item,
                translation: titleToTranslation(item.title)
            };
            if (item.tooltip) {
                header.tooltipTranslation = tooltipTitleToTranslation(header.title);
            }
            return header;
        });
        // inject translations to rows
        tableRows = tableRows.map((row) => {
            const newRow = { ...row };
            Object.entries(newRow).forEach(([key]) => {
                if (row[key].tooltip) {
                    newRow[key].tooltipTranslation = getRowTooltipTranslation(row[key].tooltip);
                }
                if (row[key].tableItem) {
                    newRow[key].tableItem = getRowItemTranslation(row[key].tableItem);
                }
            });
            return newRow;
        });

        numDisplayedRecords = numAllRecords;
    }

    function pageUpdated(event) {
        allOrdersState.actions.setOrderPage(event.detail.pageNum);
        allOrdersState.actions.getAllOrders();
    }

    function updatePaymentStatus(event) {
        allOrdersState.actions.updatePaymentStatus(event.detail.status, event.detail.orderId);
    }

    function setInitialData(orders: any[], sortingProperty) {
        allOrders = orders;
        if (orders && orders.length) {
            let ascending: boolean;
            let sortType = SORT_TYPE.date;

            switch (sortingProperty) {
                case PURCHASE_DATE:
                    ascending = purchaseDateSort === "up";
                    break;
                case FULFILLED_DATE:
                    ascending = fulfilledDateSort === "up";
                    break;
                case AMOUNT:
                    ascending = amountSort === "up";
                    sortType = SORT_TYPE.number;
                    break;
                default:
                    ascending = false;
            }
            sortedOrders = objectSort(allOrders, INITIAL_SORT, ascending, sortType);
            allOrders = sortedOrders;
        }
    }

    function handleSortToggle(sortingProperty: string) {
        let sortType = SORT_TYPE.date;
        let ascending = false;

        switch (sortingProperty) {
            case PURCHASE_DATE:
                purchaseDateSort = purchaseDateSort === "down" ? "up" : "down";
                ascending = purchaseDateSort === "up";
                break;
            case FULFILLED_DATE:
                fulfilledDateSort = fulfilledDateSort === "down" ? "up" : "down";
                ascending = fulfilledDateSort === "up";
                break;
            case AMOUNT:
                amountSort = amountSort === "down" ? "up" : "down";
                ascending = amountSort === "up";
                sortType = SORT_TYPE.number;
                break;
            default:
                break;
        }

        sortedOrders = objectSort(allOrders, sortingProperty, ascending, sortType);
        allOrders = sortedOrders;
    }

    function handleSort(event) {
        const sortItem = event.detail.sortItem;

        switch (sortItem) {
            case "Purchase date":
                handleSortToggle(PURCHASE_DATE);
                break;
            case "Fulfilled date":
                handleSortToggle(FULFILLED_DATE);
                break;
            case "Amount":
            case "Amount fulfilled":
                handleSortToggle(AMOUNT);
                break;
        }
    }

    function itemSelected(event, tableRow) {
        const url = `/merchant/${isNewDetailsPageEnabled ? "orders" : "ordersV1"}/${tableRow.ID}${
            tableRow.isLatent ? "?latent" : ""
        }`;
        if (event.metaKey) {
            window.open(url, "_blank");
        } else {
            navigate(url);
        }
    }
</script>

<div class="orders-list-container">
    <Table
        numRows={allOrdersState.numRowsPerPage}
        numAllRecords={numDisplayedRecords}
        {tableHeaders}
        {tableRows}
        {pageNum}
        useBackendPagination={true}
        {loading}
        {itemSelected}
        on:sorting={handleSort}
        on:pagechanged={pageUpdated}
        on:updatepayment={updatePaymentStatus}
    />

    {#if !loading && !numDisplayedRecords}
        <div class="w-full h-48 flex flex-col items-center pt-8">
            <img src="/pictures/mailboxstate.svg" alt="mailbox" />
            <p class="my-4 font-semibold text-black-dark text-center">
                {$_("order.table.noOrders")}
            </p>
        </div>
    {/if}
</div>

<style>
    .orders-list-container {
        overflow: auto;
        display: flex;
        flex-direction: column;
        height: 80vh;
    }
</style>
