<script lang="ts">
    import { _ } from "svelte-i18n";
    import Fa from "svelte-fa";
    import { faSpinner } from "@fortawesome/free-solid-svg-icons";

    import { recourseLimitConfig } from "@/store/merchant/account.store";
    import orderCreateStore, { selectedPaymentMethod } from "@/store/merchant/orderCreate.store";
    import API from "@/services/apiService";
    import { ORDER_FEE_URL } from "@/static/endPoints";
    import { INSTALMENT_PLAN } from "@/utilsV2/netTerms";
    import { getExtractedPaymentTermsAmount } from "@/utilsV2/helpers";
    import { type PricingPackageFee } from "@/static/types";
    import utils from "@/utilsV2/currency";

    export let payment_terms;
    export let currency;
    export let grossAmount = 0;

    const { country } = orderCreateStore;

    let feePercentage = 0;
    let feeAmount = "";
    let isLoading = false;

    const fetchPricingPackage = async (): Promise<string | void> => {
        isLoading = true;
        const isRecourseEnabled =
            $recourseLimitConfig?.merchant_recourse_limits[0].recourse_fallback_enabled || false;

        const extractedPaymentTermsAmount = getExtractedPaymentTermsAmount(payment_terms);

        API.post(ORDER_FEE_URL, {
            buyer_country_code: $country.country_code,
            approved_on_recourse: isRecourseEnabled,
            gross_amount: grossAmount,
            ...($selectedPaymentMethod === INSTALMENT_PLAN($_)
                ? {
                      order_terms: {
                          period: "MONTHLY",
                          number_of_instalments: extractedPaymentTermsAmount
                      }
                  }
                : { invoice_due_in_days: extractedPaymentTermsAmount })
        })
            .then((response: PricingPackageFee) => {
                feePercentage = response.percentage_fee;
                feeAmount = utils.formatCurrency(currency)(response.total_fee);
            })
            .catch((e) => {
                feePercentage = 0;
                feeAmount = "";
                console.log(`Failed to fetch prices, error: ${e}`);
            })
            .finally(() => {
                isLoading = false;
            });
    };
    $: if (payment_terms && grossAmount) {
        fetchPricingPackage();
    }
</script>

{#if (feePercentage && feePercentage) || isLoading}
    <div class="flex items-center bg-Background-Semantic-Info-Tertiary px-2 py-1 rounded-sm">
        <span class="mr-6">{$_("oc.yourOrderFee")}</span>
        {#if isLoading}
            <Fa icon={faSpinner} class="animate-spin" color="var(--ctnGeneralTertiary)" />
        {:else}
            <span
                id="your-order-fee-percent"
                class="mr-6 font-bold text-Content-Semantic-Info-Primary">{feePercentage}%</span
            >
            <span id="your-order-fee-amount" class="font-bold text-Content-Semantic-Info-Primary"
                >{feeAmount}</span
            >
        {/if}
    </div>
{/if}
