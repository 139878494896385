import { getBrandThemeNameByUrl, BrandThemeName } from "@two-ui/core";
// When upgrading to SvelteKit, import `browser` from `$app/environment` and replace `true` on the following line with `browser` to avoid unnecessary server-side activity.
const IS_RUNNING_IN_BROWSER = true;

// Keep this module in sync with its namesake in checkout-page and admin-portal.
// When portals is upgraded to sveltekit, move this module into a shared library and import into all three projects.

// When we have a consistent mechanism for pulling environment variables into all three projects, replace the following
// with a function that derives environment name from environment variables.

const OUR_PRODUCTION_URL = /^[a-z]+\.two\.inc$/;
const OUR_OTHER_URLS = /^[a-z]+\.([a-z]+)\.two\.inc$/;

let environmentName = IS_RUNNING_IN_BROWSER ? null : "dev";

const getEnvironmentName = (): string => {
    // This function will return one of the following:

    // production / sandbox / demo / staging / experimental / perf / cyber / dev

    if (environmentName) {
        return environmentName;
    }

    const location = IS_RUNNING_IN_BROWSER ? window.location.hostname : "[unknown]";

    if (OUR_PRODUCTION_URL.exec(location)) {
        environmentName = "production";
    } else {
        const matcher = OUR_OTHER_URLS.exec(location);

        if (matcher) {
            environmentName = matcher[1];
        } else {
            environmentName = "dev";
        }
    }

    console.info(`Environment: ${location} => ${getEnvironmentName()}`);
    return environmentName;
};

interface Branding {
    logo: string;
    displayName: string;
    fullBrandName: string;
    website: string;
    hideBuyerPortal: boolean;
    supportEmail: string;
    footerText: string;
    privacyPolicyLink: string;
    aboutLink: string;
    faviconUrl: string;
    title: string;
    productName: string;
    hasSupportChat: boolean;
    hasGoogleTagManager: boolean;
    hideRiskBearer: boolean;
    hideOrderCreationBtn: boolean;
    hideCreditLimit: boolean;
    hideOnRecourse: boolean;
    hideCustomerConfig: boolean;
    initialPhoneCountryCode: string;
    chartMainColor: string;
    chartAltColor: string;
    chartRiskAltColor: string;
}

const setBranding = (): Branding => {
    const brandThemeName = getBrandThemeNameByUrl();

    switch (brandThemeName) {
        case "achterafbetalen": {
            return {
                logo: "/pictures/logos/abn-logo.svg",
                displayName: "Achteraf betalen",
                fullBrandName: "ABN AMRO Bank N.V.",
                website: "http://portal.achterafbetalen.abnamro.nl/",
                hideBuyerPortal: true,
                supportEmail: "support@payment.abnamro.com",
                footerText: "ABN AMRO Bank N.V.",
                privacyPolicyLink:
                    "https://www.abnamrocomfin.com/nld/nl/informatie/ABN-AMRO-Commercial-Finance-en-uw-persoonsgegevens?selectedTabs=Voor%20wie%20is%20dit%20Privacy%20Statement%20bedoeld%3F",
                aboutLink:
                    "https://doorpakken.abnamro.nl/hulpmiddelen-en-diensten/achteraf-betalen/",
                faviconUrl: "/favicon-abn.ico",
                title: "Achteraf betalen portal",
                productName: "Achteraf betalen",
                hasSupportChat: false,
                hasGoogleTagManager: false,
                hideRiskBearer: true,
                hideOrderCreationBtn: true,
                hideOnRecourse: true,
                hideCreditLimit: true,
                hideCustomerConfig: true,
                initialPhoneCountryCode: "+31",
                chartMainColor: "#00A296",
                chartAltColor: "#004C4C",
                chartRiskAltColor: "#DC6803"
            };
        }
        case "choco": {
            return {
                logo: "/pictures/logos/choco-logo.png",
                displayName: "Choco",
                fullBrandName: "Choco",
                website: "https://choco.com/",
                hideBuyerPortal: true,
                supportEmail: "support@payment.choco.com",
                footerText: "Choco",
                privacyPolicyLink: "https://www.two.inc/privacy-policy",
                aboutLink: "https://choco.com/",
                faviconUrl: "/favicon-choco.ico",
                title: "Choco portal",
                productName: "Choco",
                hasSupportChat: false,
                hasGoogleTagManager: true,
                hideRiskBearer: false,
                hideOrderCreationBtn: false,
                hideCreditLimit: false,
                hideOnRecourse: false,
                hideCustomerConfig: false,
                initialPhoneCountryCode: "+44",
                chartMainColor: "#3538CD",
                chartAltColor: "#C7D7FE",
                chartRiskAltColor: "#DC6803"
            };
        }
        case "komplett": {
            return {
                logo: "/pictures/logos/komplett-two-logo.png",
                displayName: "Komplett",
                fullBrandName: "Komplett",
                website: "https://www.two.inc",
                hideBuyerPortal: true,
                supportEmail: "support@two.inc",
                footerText: "Komplett",
                privacyPolicyLink: "https://www.two.inc/privacy-policy",
                aboutLink: "https://support.two.inc/hc/en-us/categories/18513474186525-What-is-Two",
                faviconUrl: "/favicon-two.ico",
                title: "Komplett portal",
                productName: "Komplett",
                hasSupportChat: false,
                hasGoogleTagManager: true,
                hideRiskBearer: false,
                hideOrderCreationBtn: false,
                hideCreditLimit: false,
                hideOnRecourse: false,
                hideCustomerConfig: false,
                initialPhoneCountryCode: "+47",
                chartMainColor: "#F8B912",
                chartAltColor: "#1F1A49",
                chartRiskAltColor: "#DC6803"
            };
        }
        case "itegra": {
            return {
                logo: "/pictures/logos/itegra-two-logo.png",
                displayName: "Itegra",
                fullBrandName: "Itegra",
                website: "https://www.two.inc",
                hideBuyerPortal: true,
                supportEmail: "support@two.inc",
                footerText: "Itegra",
                privacyPolicyLink: "https://www.two.inc/privacy-policy",
                aboutLink: "https://support.two.inc/hc/en-us/categories/18513474186525-What-is-Two",
                faviconUrl: "/favicon-two.ico",
                title: "Itegra portal",
                productName: "Itegra",
                hasSupportChat: false,
                hasGoogleTagManager: true,
                hideRiskBearer: false,
                hideOrderCreationBtn: false,
                hideCreditLimit: false,
                hideOnRecourse: false,
                hideCustomerConfig: false,
                initialPhoneCountryCode: "+47",
                chartMainColor: "#EA6A20",
                chartAltColor: "#3A1D0D",
                chartRiskAltColor: "#DC6803"
            };
        }
        default: {
            return {
                logo: "/pictures/logos/logo.svg",
                displayName: "Two",
                fullBrandName: "TWO B2B LTD",
                website: "https://www.two.inc",
                hideBuyerPortal: false,
                supportEmail: "support@two.inc",
                footerText: "Two AS",
                privacyPolicyLink: "https://www.two.inc/privacy-policy",
                aboutLink: "https://support.two.inc/hc/en-us/categories/18513474186525-What-is-Two",
                faviconUrl: "/favicon-two.ico",
                title: "Two Portal",
                productName: "Two",
                hasSupportChat: true,
                hasGoogleTagManager: true,
                hideRiskBearer: false,
                hideOrderCreationBtn: false,
                hideCreditLimit: false,
                hideOnRecourse: false,
                hideCustomerConfig: false,
                initialPhoneCountryCode: "+44",
                chartMainColor: "#3538CD",
                chartAltColor: "#C7D7FE",
                chartRiskAltColor: "#DC6803"
            };
        }
    }
};

const branding: Branding = setBranding();

export default {
    branding
};

export { getEnvironmentName, IS_RUNNING_IN_BROWSER };
