export const CREATE_ORDER = "create-order";
export const CREATE_ORDER_PATH = `/merchant/${CREATE_ORDER}`;

export const BUYER_PORTAL_LINK = "/auth/buyer/login";
export const ORDERS_PAGE_LINK = "/merchant/orders";

export const CUSTOMER_PAGE_ROUTE = "/merchant/customers/";
export const CREDIT_BUILDER_LINK = "https://checkout.two.inc/credit_limit_change/credit_builder";

export const DECLINE_REASON = {
    BUYER_NOT_FOUND: "Could not retrieve a credit report for the buyer.",
    NON_TRADING_BUYER:
        "Buyer is registered as non-trading or did not have any trading activity in the last year."
};
